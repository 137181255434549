var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "mainheader"
  }, [_c('v-row', [_vm.showProjectDropdown ? _c('v-col', [_vm.shouldProjectDropDownRender ? _c('project-dropdown') : _vm._e()], 1) : _vm._e(), _c('v-col', [_c('user-menu')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }