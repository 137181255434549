<template>
  <div class="usermenu">
    <v-divider vertical />
    <div class="usermenu--options">
      <v-avatar
        :color="generateAvatarColor"
        size="25"
      >
        <span class="white--text text-h6">{{ generatedAvatarInitials }}</span>
      </v-avatar>
      <span class="username">
        {{ userData.user.username }}
      </span>
      <v-btn icon>
        <v-icon
          class="usermenu--popuptrigger"
          @click="onClickDropdownMenu"
        >
          expand_more
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="showDropdownMenu"
      class="usermenu--dropdown px-6"
    >
      <v-row
        justify="space-between"
        class="pt-4"
      >
        <v-col class="usermenu--userinfo">
          <ul class="usermenu--list">
            <li>
              <p class="usermenu--username font-weight-bold mb-2">
                {{ generateUserName }}
              </p>
            </li>
            <li>
              <p class="text-truncate">
                {{ userData.user.username }}
              </p>
            </li>
            <li>
              <router-link
                class="usermenu--link pb-0"
                to="/logout"
              >
                <v-btn class="usermenu--signout-btn">
                  Sign out
                </v-btn>
              </router-link>
            </li>
          </ul>
        </v-col>
        <v-col class="usermenu--avatar">
          <v-avatar
            :color="generateAvatarColor"
            height="64px"
            width="64px"
          >
            <span class="white--text text-h6">{{ generatedAvatarInitials }}</span>
          </v-avatar>
        </v-col>
      </v-row>

      <v-divider class="usermenu--divider" />

      <v-row
        justify="space-around"
        class="pt-4"
      >
        <v-col>
          <ul class="usermenu--list">
            <li class="pb-3">
              <a
                class="usermenu--link"
                href="https://brandwatch-reviews-help.brandwatch.com/hc/en-us"
                target="_blank"
              >
                <span>Help</span>
                <v-icon>open_in_new</v-icon>
              </a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import didNotClickOn from '@/utils/didNotClickOn'
import { getColorForNumber } from '@/utils/constants'

import { mapState } from 'vuex'

export default {
  name: 'UserMenu',
  data: () => ({
    showDropdownMenu: false,
  }),
  computed: {
    ...mapState('users', {
      userData: 'userData',
    }),
    generateUserName() {
      const { firstName, lastName } = this.userData.user

      if (!firstName && !lastName) {
        return this.userData.client.name
      }

      return `${firstName} ${lastName}`
    },
    generatedAvatarInitials() {
      let generatedInitials = ''
      const { username } = this.userData.user
      const [name, domain] = username.split('@')
      if (name.includes('.')) {
        const [firstName, lastName] = name.split('.')
        generatedInitials = `${firstName[0]}${lastName[0]}`
      } else {
        generatedInitials = `${name[0]}${domain[0]}`
      }
      return generatedInitials.toUpperCase()
    },
    generateAvatarColor() {
      const { username } = this.userData.user
      const usernameChars = username.split('')
      const numbersArray = usernameChars.map((char) => char.codePointAt())
      const numberForUsername = numbersArray.reduce((prev, curr) => prev + curr, 0)
      return getColorForNumber(numberForUsername)
    },
  },
  mounted() {
    document.body.addEventListener('click', this.onClickDocument)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.onClickDocument)
  },
  methods: {
    onClickDropdownMenu() {
      this.showDropdownMenu = !this.showDropdownMenu
    },
    onClickDropdownMenuItem({ key }) {
      if (key === 'admin') {
        this.$router.push('/client/admin')
          .catch((e) => {
            if (e.name !== 'NavigationDuplicated') {
              throw e
            }
          })
      }
    },
    onClickDocument(e) {
      const { target } = e
      if (!didNotClickOn(target, {
        classes: ['usermenu--popuptrigger'],
      })) {
        return
      }
      this.showDropdownMenu = false
    },
  },
}
</script>

<style scoped>
.username {
  margin-left: 5px;
}

.usermenu {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.usermenu--options {
  height: 25px;
  display: flex;
  align-items: center;
}

/deep/ .v-divider {
  margin: 0 12px 5px 0;
  border-color: var(--r-dark-grey);
  position: relative;
  top: 3px;
}

.usermenu--dropdown {
  position: absolute;
  right: 0;
  top: 30px;
  width: 320px;
  background: white;
  z-index: 1;
  border-radius: var(--r-border-radius);
  border: 2px var(--r-light-grey) solid;
}

.usermenu--list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.usermenu--list li:last-child {
  padding-bottom: 16px !important;
}

.usermenu--userinfo {
  width: 200px;
}

.usermenu--avatar {
  text-align: right;
}

.usermenu--signout-btn {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.usermenu--divider {
  border-color: var(--r-grey) !important;
  width: 100%;
}

.usermenu--link {
  font-size: 13px;
  line-height: 20px;
  text-decoration: none;
  color: var(--r-text-color);
}

.usermenu--link span {
  padding-right: 6px;
}
</style>
