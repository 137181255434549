var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('aside', {
    staticClass: "mainnavigation"
  }, [_vm._m(0), _c('nav', {
    staticClass: "navigation-items"
  }, [_c('v-list', [_vm._l(_vm.mainNavigationItems, function (item) {
    return [item.children ? _c('v-list-group', {
      key: item.title,
      attrs: {
        "prepend-icon": item.icon,
        "value": _vm.$route.path.startsWith('/admin')
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(item.children, function (children) {
      return _c('v-list-item', {
        key: children.title,
        attrs: {
          "to": children.link
        }
      }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(children.icon))])], 1), _c('v-list-item-title', [_vm._v(_vm._s(children.title))])], 1);
    }), 1) : _c('v-list-item', {
      key: item.title,
      attrs: {
        "to": item.link
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)];
  }), _c('v-divider', {
    staticClass: "divider"
  }), _c('v-list-item', {
    staticClass: "help-link",
    on: {
      "click": _vm.switchShowSitesCoverageModal
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("help_outline")])], 1), _c('v-list-item-title', [_vm._v("Sites coverage")]), _c('v-list-item-action', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" info ")])], 1)], 1), _c('v-list-item', {
    staticClass: "help-link",
    attrs: {
      "target": "_blank",
      "to": "//brandwatch-reviews-help.brandwatch.com/hc/en-us"
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("help_outline")])], 1), _c('v-list-item-title', [_vm._v("Help and Support")]), _c('v-list-item-action', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" exit_to_app ")])], 1)], 1)], 2)], 1), _c('sites-coverage', {
    attrs: {
      "show-sites-coverage-modal": _vm.showSitesCoverageModal
    },
    on: {
      "close": _vm.switchShowSitesCoverageModal
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "navigation-header"
  }, [_c('img', {
    attrs: {
      "src": "/brandwatch-logo.svg",
      "alt": "brandwatch logo"
    }
  }), _c('h2', {
    staticClass: "navigation-header--appname"
  }, [_vm._v(" Brandwatch Reviews ")])]);

}]

export { render, staticRenderFns }