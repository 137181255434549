var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showSitesCoverageModal ? _c('r-modal', {
    attrs: {
      "fetching": _vm.fetchingSupportedDomains,
      "title": "Sites Coverage"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "sitescoverage-content"
        }, [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchingSupportedDomainsError]
          }
        }), _c('div', {
          staticClass: "d-flex align-center my-2"
        }, [_c('r-search-input', {
          staticClass: "sitescoverage--search",
          staticStyle: {
            "width": "300px"
          },
          attrs: {
            "value": _vm.search
          },
          on: {
            "change": _vm.onChangeSearch
          }
        })], 1), _c('r-table', {
          key: "supporteddomainstable",
          staticClass: "supporteddomains--table",
          attrs: {
            "is-selectable": false,
            "columns": _vm.tableColumns,
            "rows": _vm.supportedDomains,
            "total-rows": _vm.totalSupportedDomains,
            "page": _vm.page,
            "page-size": _vm.pageSize,
            "loading": _vm.fetchingSupportedDomains
          },
          on: {
            "change:page": _vm.onChangePage,
            "change:page-size": _vm.onChangePageSize
          },
          scopedSlots: _vm._u([{
            key: "item.exampleUrls",
            fn: function (_ref) {
              var item = _ref.item;
              return _vm._l(item.exampleUrls, function (url, index) {
                return _c('a', {
                  key: index,
                  staticClass: "sitescoverage--example-url text-truncate",
                  attrs: {
                    "href": url,
                    "target": "_blank"
                  }
                }, [_vm._v(_vm._s(url))]);
              });
            }
          }], null, false, 1369325800)
        })], 1)];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-center justify-end"
        }, [_c('r-button', {
          staticClass: "close-btn mr-3",
          attrs: {
            "outlined": true,
            "data-testid": "close",
            "label": "Close"
          },
          on: {
            "click": _vm.onClose
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 513890060)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }