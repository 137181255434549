<template>
  <header class="mainheader">
    <v-row>
      <v-col v-if="showProjectDropdown">
        <project-dropdown v-if="shouldProjectDropDownRender" />
      </v-col>
      <v-col>
        <user-menu />
      </v-col>
    </v-row>
  </header>
</template>

<script>
import UserMenu from '@/components/app/UserMenu'
import ProjectDropdown from '@/components/app/data/ProjectDropdown'

export default {
  name: 'MainHeader',
  components: {
    UserMenu,
    ProjectDropdown,
  },
  props: {
    showProjectDropdown: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    shouldProjectDropDownRender() {
      return this.$route.path.startsWith('/data') || this.$route.path.startsWith('/dashboards')
    },
  },
}
</script>

<style scoped>
.mainheader {
  display: flex;
  align-items: center;
  height: 41.5px;
  padding: 12px 20px;
  border-bottom: 1px solid;
  border-color: var(--r-grey);
}

@media all and (max-width: 1120px) {
  .mainheader {
    flex-wrap: wrap;
    height: unset;
    min-width: 1024px;
  }
}
</style>
