<template>
  <div class="app-page">
    <main-navigation :main-navigation-items="mainNavigationItems" />
    <div class="main-container">
      <main-header :show-project-dropdown="showProjectDropdown" />
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainNavigation from '@/components/app/MainNavigation'
import MainHeader from '@/components/app/MainHeader'

export default {
  name: 'AppPage',
  components: {
    MainNavigation,
    MainHeader,
  },
  props: {
    showProjectDropdown: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    baseNavItems: [
      { title: 'Data', icon: 'library_add', link: '/data' },
      { title: 'Dashboards', icon: 'insert_chart', link: '/dashboards' },
      { title: 'Tools', icon: 'build', link: '/tools' },
    ],
  }),
  computed: {
    ...mapGetters('users', [
      'isAdmin',
    ]),
    mainNavigationItems() {
      const { baseNavItems } = this.$data
      if (!this.isAdmin) {
        return baseNavItems
      }
      return baseNavItems.concat([
        {
          title: 'Admin',
          icon: 'security',
          link: '/admin',
          children: [
            { title: 'Clients', link: '/admin/clients' },
            { title: 'Urls', link: '/admin/urls' },
          ],
        },
      ])
    },
  },
}
</script>

<style scoped>
.app-page {
  margin: 0;
  height: 100vh;
  min-width: 1110px;
  display: grid;
  grid-template-columns: auto 1fr;
}
.container {
  max-width: 1024px;
}

@media all and (max-width: 1120px) {
  .app-page {
    max-width: 1110px;
    min-width: unset;
  }

  .main-container {
    overflow-x: auto;
    max-width: calc(100vw - 225px);
    min-width: calc(100vw - 225px);
  }

  .container {
    min-width: 1024px;
  }

}
</style>
