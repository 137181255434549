<template>
  <aside class="mainnavigation">
    <header class="navigation-header">
      <img
        src="/brandwatch-logo.svg"
        alt="brandwatch logo"
      >
      <h2 class="navigation-header--appname">
        Brandwatch Reviews
      </h2>
    </header>
    <nav class="navigation-items">
      <v-list>
        <template
          v-for="item in mainNavigationItems"
        >
          <v-list-group
            v-if="item.children"
            :key="item.title"
            :prepend-icon="item.icon"
            :value="$route.path.startsWith('/admin')"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="children in item.children"
              :key="children.title"
              :to="children.link"
            >
              <v-list-item-icon>
                <v-icon>{{ children.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ children.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-divider class="divider" />
        <v-list-item
          class="help-link"
          @click="switchShowSitesCoverageModal"
        >
          <v-list-item-icon>
            <v-icon>help_outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Sites coverage</v-list-item-title>

          <v-list-item-action>
            <v-icon small>
              info
            </v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          class="help-link"
          target="_blank"
          to="//brandwatch-reviews-help.brandwatch.com/hc/en-us"
        >
          <v-list-item-icon>
            <v-icon>help_outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Help and Support</v-list-item-title>

          <v-list-item-action>
            <v-icon small>
              exit_to_app
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </nav>
    <sites-coverage
      :show-sites-coverage-modal="showSitesCoverageModal"
      @close="switchShowSitesCoverageModal"
    />
  </aside>
</template>

<script>
import SitesCoverage from '@/components/app/info/SitesCoverage.vue'

export default {
  name: 'MainNavigation',
  components: {
    SitesCoverage,
  },
  props: {
    mainNavigationItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showSitesCoverageModal: false,
    }
  },
  methods: {
    switchShowSitesCoverageModal() {
      this.$data.showSitesCoverageModal = !this.$data.showSitesCoverageModal
    },
  },
}
</script>

<style scoped>
.mainnavigation {
  width: 209px;
  height: 100%;
  background-color: rgb(243,243,243);
}
.navigation-header {
  padding: 8px;

  display: flex;
  gap: 8px;

  background-color: rgb(108,108,108);
  color: rgb(255, 255, 255);
}
.navigation-header--appname {
  font-size: var(--r-font-size);
}
.navigation-header > img {
  width: 25px;
}
.navigation-item,
.help-link {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: var(--r-text-color);
}

/deep/ .v-list {
  padding: 0;
  background: transparent !important;
}
/deep/ .v-list-item__icon {
  min-width: 11px;
  margin: 12px 12px 12px 0 !important;
}
/deep/ .v-list-item {
  height: 36px;
  padding-left: 26px;
  color: var(--r-text-color) !important;
}
/deep/ .v-list-item:hover {
  background-color: rgb(216,216,216);
  cursor: pointer;
}
/deep/ .v-list-group__items .v-list-item__title {
  margin-left: 12px;
}
</style>
