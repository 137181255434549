<template>
  <div>
    <r-modal
      v-if="showSitesCoverageModal"
      :fetching="fetchingSupportedDomains"
      title="Sites Coverage"
    >
      <template v-slot:content>
        <div class="sitescoverage-content">
          <r-error-message :errors="[fetchingSupportedDomainsError]" />
          <div class="d-flex align-center my-2">
            <r-search-input
              :value="search"
              class="sitescoverage--search"
              style="width:300px"
              @change="onChangeSearch"
            />
          </div>

          <r-table
            key="supporteddomainstable"
            class="supporteddomains--table"
            :is-selectable="false"
            :columns="tableColumns"
            :rows="supportedDomains"
            :total-rows="totalSupportedDomains"
            :page="page"
            :page-size="pageSize"
            :loading="fetchingSupportedDomains"
            @change:page="onChangePage"
            @change:page-size="onChangePageSize"
          >
            <template v-slot:item.exampleUrls="{ item }">
              <a
                v-for="(url, index) in item.exampleUrls"
                :key="index"
                :href="url"
                class="sitescoverage--example-url text-truncate"
                target="_blank"
              >{{ url }}</a>
            </template>
          </r-table>
        </div>
      </template>

      <template v-slot:customActions>
        <div class="d-flex align-center justify-end">
          <r-button
            :outlined="true"
            data-testid="close"
            class="close-btn mr-3"
            label="Close"
            @click="onClose"
          />
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import RTable from '@/components/library/organisms/RTable'
import RModal from '@/components/library/organisms/RModal'
import RButton from '@/components/library/atoms/RButton'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RSearchInput from '@/components/library/molecules/RSearchInput'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'SitesCoverage',
  components: {
    RModal,
    RTable,
    RButton,
    RSearchInput,
    RErrorMessage,
  },
  props: {
    showSitesCoverageModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    page: 1,
    pageSize: 50,
    tableColumns: [
      {
        key: 'hostname',
        label: 'Domain',
        hideable: true,
        sortable: false,
        width: 120,
      },
      {
        key: 'exampleUrls',
        label: 'URL Sample/Format',
        hideable: true,
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState('appInfo', [
      'supportedDomains',
      'fetchingSupportedDomainsError',
      'fetchingSupportedDomains',
      'totalSupportedDomains',
    ]),
  },
  watch: {
    showSitesCoverageModal(newValue) {
      if (newValue) {
        this.fetchData()
      }
    },
  },
  methods: {
    ...mapActions('appInfo', [
      'fetchSupportedDomains',
      'clearSuggestedDomains',
    ]),
    fetchData() {
      const fetchParams = {
        search: this.$data.search,
        page: this.$data.page,
        pageSize: this.$data.pageSize,
      }
      this.fetchSupportedDomains(fetchParams)
    },
    onChangeSearch({ value }) {
      this.$data.search = value
      this.$data.page = 1
      this.fetchData()
    },
    onChangePage({ value }) {
      this.$data.page = value
      this.fetchData()
    },
    onChangePageSize({ value }) {
      this.$data.page = 1
      this.$data.pageSize = value
      this.fetchData()
    },
    onClose() {
      this.$data.search = ''
      this.$data.page = 1
      this.$data.pageSize = 50
      this.clearSuggestedDomains()
      this.$emit('close')
    },
  },
}
</script>
<style>
.content-container {
  padding-bottom: 0;
}

.content-container > .sitescoverage-content {
  min-width: 700px;
}

.supporteddomains--table {
  height: 580px !important;
}

.sitescoverage--example-url{
  width: 550px;
  display: block;
}
</style>
