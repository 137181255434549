<template>
  <v-row class="ma-0 projectdropdown">
    <span class="projectdropdown--label">{{ $t('projectDropdown.title') }}</span>
    <r-select
      :key="`projectdropdown--rselect--${selectRenderCount}`"
      class="projectdropdown--select"
      :items="items"
      :show-search="false"
      :allow-empty="false"
      :infinite="true"
      @change="onChangeSelectedProject"
      @click:append-icon="onClickCog"
      @change:search="onSearchChange"
      @on:get-more="onGetMoreItems"
    >
      <template v-slot:prepend-item>
        <v-col
          class="d-flex p-0 rselectitem"
        >
          <button
            class="projectdropdown--create-project px-2 py-2 rselectitem--row"
            @click="onClickCreateNewProject"
          >
            <v-icon dense>
              add
            </v-icon>
            <span class="ml-2">Create a new project</span>
          </button>
        </v-col>
      </template>
    </r-select>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect'
import { ALL_DATA_PROJECT } from '@/utils/constants'
import { generateParams } from '@/utils/store'

const DEFAULT_PAGE = 1

export default {
  name: 'ProjectDropdown',
  components: {
    RSelect,
  },
  data: () => ({
    selectRenderCount: 0,
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('projects', [
      'fetchingProjects',
      'projects',
      'fetchProjectsError',
    ]),
    items() {
      const base = { appendIcon: 'settings' }
      const { selectedProjectId } = this
      return [
        {
          label: ALL_DATA_PROJECT.name,
          value: ALL_DATA_PROJECT.id,
          selected: !selectedProjectId,
          appendIcon: 'settings',
        },
      ].concat(
        this.projects.map(({ _id, name }) => ({
          ...base,
          label: name,
          value: _id,
          selected: selectedProjectId === _id,
        })),
      )
    },
  },
  watch: {
    projects() {
      const pids = this.projects.map(({ _id }) => _id)
      if (!pids.includes(this.selectedProjectId)) {
        this.selectProject({ projectId: ALL_DATA_PROJECT.id })
      }
    },
  },
  mounted() {
    this.fetchProjects()
  },
  methods: {
    ...mapActions('projects', [
      'fetchProjects',
      'selectProject',
    ]),
    ...mapActions('dashboards', {
      setPageDashboards: 'setPage',
    }),
    ...mapActions('alerts', {
      setPageAlerts: 'setPage',
    }),
    ...mapActions('brands', {
      setPageBrands: 'setPage',
    }),
    ...mapActions('categories', {
      setPageCategories: 'setPage',
    }),
    ...mapActions('products', {
      setPageProducts: 'setPage',
    }),
    ...mapActions('tags', {
      setPageTags: 'setPage',
    }),
    ...mapActions('urls', {
      setPageUrls: 'setPage',
    }),
    resetToPageOne() {
      this.setPageDashboards({ page: DEFAULT_PAGE })
      this.setPageAlerts({ page: DEFAULT_PAGE })
      this.setPageBrands({ page: DEFAULT_PAGE })
      this.setPageCategories({ page: DEFAULT_PAGE })
      this.setPageProducts({ page: DEFAULT_PAGE })
      this.setPageTags({ page: DEFAULT_PAGE })
      this.setPageUrls({ page: DEFAULT_PAGE })
    },
    onClickCreateNewProject() {
      this.$router.push('/projects/add')
    },
    onClickCog({ value }) {
      this.$router.push(`/projects/${value}/edit`)
    },
    onChangeSelectedProject(item) {
      const { value } = item
      this.selectProject({ projectId: value })
      this.resetToPageOne()
      this.$data.selectRenderCount += 1
    },
    onGetMoreItems() {
      const pageParams = generateParams('projects')
      if (!pageParams) {
        return
      }
      let params = {
        projectId: this.projectId,
        isInfinityScroll: true,
        search: this.searchValue,
      }
      params = { ...params, ...pageParams }
      this.fetchProjects(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchProjects({ projectId: this.projectId, search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
.projectdropdown--label {
  margin-top: 6px;
  margin-right: 24px;
}
.projectdropdown--select {
  width: 296px;
}
.projectdropdown--create-project {
  width: 100%;
  display: flex;
  align-items: center;
}
/deep/ .selector-popup--items .rselectitem:nth-child(1) {
  border-top: 1px solid var(--r-border-color) !important;
}
/deep/ .selector-popup--items .rselectitem:last-child {
  border-bottom: none !important;
}
.projectdropdown--create-project span {
  margin-left: 11px;
  font-size: 12px;
  color: rgba(63, 63, 63, 0.75);
}
.rselectitem .rselectitem--row:hover {
  background: var(--r-light-grey);
  cursor: pointer;
}
/deep/ .projectdropdown--select .selector-popup--items {
  padding: 0 8px !important;
}
.rselectitem {
  padding: 0 8px !important;
}
</style>
